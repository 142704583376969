<template>
      <div class="row align-items-center justify-content-center">
      <div class="col-6">

        <MapComponent :lat="59.3287" :lng="18.0717" :lat1="37.7510" :lng1="-97.8220"></MapComponent><br>

        <div class="row">
          <div class="col-5">
                <h4>🇸🇪swe-b1</h4>
                <b>drusha.network AS65001</b>
          </div>
          <div class="col-2 justify-content-center align-items-center">
            <h3><i class="bi bi-arrow-left-right"></i></h3>
          </div>
          <div class="col-5">
                <h4>🇺🇸 216.58.206.46</h4>
                <b>Google Inc. AS15169</b>
          </div>
        </div>
        <br>
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col" style="text-align: left;">
                <div class="spinner-border" style="width: 1rem; height: 1rem;" role="status" v-if="!done">
                </div>
                <span v-if="done"><i class="bi bi-check-lg" style="color: green;"></i></span> <span>{{ this.requestStatus }}</span>
              </div>
              <div class="col">
                <div style="text-align: right;">
                  <span class="clickable" v-on:click="this.showToast('Copied!')"><i class="bi bi-clipboard"></i> Copy </span>
                  <span class="clickable" v-on:click="this.showToast('Link copied!')"><i class="bi bi-link-45deg"></i> Link </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" style="text-align: left;" v-if="this.result != ''">
            <pre v-html="this.result"></pre>
          </div>
        </div>
        <br>
      </div>
    </div>
</template>

<script>
import MapComponent from './MapComponent.vue';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {

  components: {
    MapComponent
  },
  name: 'PingComponent',
  data(){
    return {
      requestStatus: '',
      result: '',
      done: false
    }
  },
  mounted() {
    this.spoofPing();
  },
  methods: {
    spoofPing(){
      let i = 0;
      this.result = ""
      this.requestStatus = "Accepted your request!"
      setTimeout(() => {
        this.requestStatus = "Connecting to device...";
      }, 200);

      setTimeout(() => {
        this.requestStatus = "Executing command...";
      }, 500);

      let timer = setInterval(() => {
        this.requestStatus = "Command running...";
        if (i === 0) {
          this.result = "PING google.com (216.58.206.46): 56 data bytes"
        } else {
          this.result += `\n${64} bytes from 216.58.206.46: icmp_seq=${i-1} ttl=55 time=${Math.floor(Math.random() * 100)}.${Math.floor(Math.random() * 100)} ms`
        }
        i++;
        if (i > 5) {
          clearInterval(timer)
          this.result += `\n\n--- google.com ping statistics ---\n5 packets transmitted, 5 packets received, 0.0% packet loss\nround-trip min/avg/max/stddev = ${Math.floor(Math.random() * 100)}.${Math.floor(Math.random() * 100)}/${Math.floor(Math.random() * 100)}.${Math.floor(Math.random() * 100)}/${Math.floor(Math.random() * 100)}.${Math.floor(Math.random() * 100)}/${Math.floor(Math.random() * 100)}.${Math.floor(Math.random() * 100)} ms`
          this.requestStatus = "Request executed!"
          this.done = true
        }
      }, 1000)
    },
    showToast(message){
      createToast(message,
        {
        hideProgressBar: 'true',
        showIcon: 'false',
        showCloseButton: 'false',
        swipeClose: 'false',
        transition: 'slide',
        type: 'default',
        })
    },
    async setupMapKitJS() {
      if (!window.mapkit || window.mapkit.loadedLibraries.length === 0) {
        await new Promise(resolve => { window.initMapKit = resolve });
        delete window.initMapKit;
      }
    },
    async initMap() {
      await this.setupMapKitJS();
      let mapkit = window.mapkit;
      const cupertino = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(37.3316850890998, -122.030067374026),
        new mapkit.CoordinateSpan(0.167647972, 0.354985255)
      );

      // Create a map in the element whose ID is "map-container".
      window.mapInstance = new mapkit.Map("map-container");
      window.mapInstance.region = cupertino;
      window.mapInstance.mapType = mapkit.Map.MapTypes.Satellite;
      window.mapInstance.showsCompass = mapkit.FeatureVisibility.Hidden;
      // Hide the map type and the zoom controls.
      window.mapInstance.showsMapTypeControl = false;
      window.mapInstance.showsZoomControl = false;

    },
    drawCurvedLine(startLat, startLng, endLat, endLng) {
      const mapkit = window.mapkit;

      // remove existing line
      if (window.mapInstance.overlays.length > 0) {
        window.mapInstance.removeOverlay(window.mapInstance.overlays[0]);
      }

      // Создаем начальную и конечную координаты
      const startPoint = new mapkit.Coordinate(startLat, startLng);
      const endPoint = new mapkit.Coordinate(endLat, endLng);

      // Создаем массив координат для линии
      const coordinates = [startPoint, endPoint];

      // Создаем полилинию
      const line = new mapkit.PolylineOverlay(coordinates, {
        style: new mapkit.Style({
          strokeColor: '#007aff',  // Синий цвет
          lineWidth: 3,            // Толщина линии
          lineDash: []              // Сплошная линия
        })
      });

      // Добавляем линию на карту
      window.mapInstance.addOverlay(line);

      const minLat = Math.min(startLat, endLat) - 10;
      const maxLat = Math.max(startLat, endLat) + 10;
      const minLng = Math.min(startLng, endLng) - 10;
      const maxLng = Math.max(startLng, endLng) + 10;

      console.log(minLat, maxLat, minLng, maxLng)

      var region = new mapkit.BoundingRegion(maxLat, maxLng, minLat, minLng);
      window.mapInstance.setRegionAnimated(region.toCoordinateRegion(), true);
    }
  }

}
</script>

<style scoped>
</style>