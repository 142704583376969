<template>
        <div class="map-wrapper">
        <div id="map-container"></div>
        </div>
</template>

<script>
export default {

    name: 'MapComponent',
    props: ['lat', 'lng', 'lat1', 'lng1'],
    mounted() {
        this.initMap();
    },
    methods: {

        async setupMapKitJS() {
            if (!window.mapkit || window.mapkit.loadedLibraries.length === 0) {
                await new Promise(resolve => { window.initMapKit = resolve });
                delete window.initMapKit;
            }
        },
        async initMap() {
            await this.setupMapKitJS();
            let mapkit = window.mapkit;
            const cupertino = new mapkit.CoordinateRegion(
                new mapkit.Coordinate(37.3316850890998, -122.030067374026),
                new mapkit.CoordinateSpan(0.167647972, 0.354985255)
            );

            // Create a map in the element whose ID is "map-container".
            window.mapInstance = new mapkit.Map("map-container");
            window.mapInstance.region = cupertino;
            window.mapInstance.mapType = mapkit.Map.MapTypes.Satellite;
            window.mapInstance.showsCompass = mapkit.FeatureVisibility.Hidden;
            // Hide the map type and the zoom controls.
            window.mapInstance.showsMapTypeControl = false;
            window.mapInstance.showsZoomControl = false;
            this.drawCurvedLine(this.lat, this.lng, this.lat1, this.lng1)
        },
        drawCurvedLine(startLat, startLng, endLat, endLng) {
            const mapkit = window.mapkit;

            // remove existing line
            if (window.mapInstance.overlays.length > 0) {
                window.mapInstance.removeOverlay(window.mapInstance.overlays[0]);
            }

            // Создаем начальную и конечную координаты
            const startPoint = new mapkit.Coordinate(startLat, startLng);
            const endPoint = new mapkit.Coordinate(endLat, endLng);

            // Создаем массив координат для линии
            const coordinates = [startPoint, endPoint];

            // Создаем полилинию
            const line = new mapkit.PolylineOverlay(coordinates, {
                style: new mapkit.Style({
                    strokeColor: '#007aff',  // Синий цвет
                    lineWidth: 3,            // Толщина линии
                    lineDash: []              // Сплошная линия
                })
            });

            // Добавляем линию на карту
            window.mapInstance.addOverlay(line);

            const minLat = Math.min(startLat, endLat) - 10;
            const maxLat = Math.max(startLat, endLat) + 10;
            const minLng = Math.min(startLng, endLng) - 10;
            const maxLng = Math.max(startLng, endLng) + 10;

            console.log(minLat, maxLat, minLng, maxLng)

            var region = new mapkit.BoundingRegion(maxLat, maxLng, minLat, minLng);
            window.mapInstance.setRegionAnimated(region.toCoordinateRegion(), true);
        }
    }
}
</script>

<style scoped>
#map-container {
    width: 100%;
    height: 250px;
}

.map-wrapper {
    border-radius: 0.375rem;
    overflow: hidden;
}
</style>