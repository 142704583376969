<template>
      <div class="row align-items-center justify-content-center">
      <div class="col-6">
        <div class="row">
          <div class="col-5">
                <h4>🇸🇪swe-b1</h4>
                <b>drusha.network AS65001</b>
          </div>
          <div class="col-2 justify-content-center align-items-center">
            <h3><i class="bi bi-arrow-left-right"></i></h3>
          </div>
          <div class="col-5">
                <h4>🇺🇸 216.58.206.46</h4>
                <b>Google Inc. AS15169</b>
          </div>
        </div>
        <br>
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col" style="text-align: left;">
                <div class="spinner-border" style="width: 1rem; height: 1rem;" role="status" v-if="!done">
                </div>
                <span v-if="done"><i class="bi bi-check-lg" style="color: green;"></i></span> <span>{{ this.requestStatus }}</span>
              </div>
              <div class="col">
                <div style="text-align: right;">
                  <span class="clickable"><i class="bi bi-clipboard"></i> Copy </span>
                  <span class="clickable"><i class="bi bi-link-45deg"></i> Link </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" style="text-align: left;" v-if="this.result != ''">
            <pre v-html="this.result"></pre>
          </div>
        </div>
        <br>
      </div>
    </div>
</template>

<script>
export default {

  name: 'BGPComponent',
  data(){
    return {
      requestStatus: '',
      result: '',
      done: false
    }
  },
  mounted() {
    this.showBGPRoute()
  },
  methods: {
    showBGPRoute(){
      let i = 0;
      this.result = ""
      this.requestStatus = "Accepted your request!"
      setTimeout(() => {
        this.requestStatus = "Connecting to device...";
      }, 200);

      setTimeout(() => {
        this.requestStatus = "Executing command...";
      }, 500);

      setInterval(() => {
        this.requestStatus = "Command running...";
        if (i === 0) {
          this.result = "BGP routing table entry for 1.1.1.0/24\nVersions:\n  Process           bRIB/RIB  SendTblVer\n  Speaker         1211629403  1211629403\nLast Modified: Oct  4 16:18:48.758 for 1w0d\nPaths: (1 available, best #1)\n  Path #1: Received by speaker 0\n  13335, (aggregated by 13335 10.34.12.44)\n    38.28.8.14 (metric 103030) from 38.28.8.21 (38.28.8.14)\n      Origin IGP, localpref 130, valid, internal, best, group-best, import-candidate\n      Received Path ID 0, Local Path ID 1, version 1211629403\n      Community: 174:991 174:3000 174:3290 174:12001 174:20999 174:21201 174:22038\n      Originator: 38.28.8.14, Cluster list: 38.28.8.21"
          this.requestStatus = "Request executed!"
          this.done = true
        }
      }, 1000)
    }
  }

}
</script>

<style scoped>
</style>