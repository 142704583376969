<template>
  <div class="container">
    <h2>drusha.network Looking Glass</h2>
    <br>

    <!-- HOST SELECTOR -->
    <div id="host-selector" v-if="selectedNode === null">
      <b>Select a node to issue request from:</b>
      <br><br>
      <div class="row align-items-center justify-content-center" v-if="this.config && this.config.nodes">

        <div class="col-2" v-for="node in this.config.nodes" :key="node.id">
          <div class="card" v-on:click="selectedNode = node.id">
            <div class="card-body">
              <h1>{{ node.emoji }}</h1>
              <h5 class="card-title"><b>{{ node.name }}</b></h5>
              {{ node.description }}
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- QUERY SELECTOR -->
    <div v-if="selectedNode != null && step != 4">
    <div class="row align-items-center justify-content-center" v-if="this.config && this.config.commands">

      <div class="col-2" v-for="command in this.config.commands" :key="command">
        <div class="card card-sm" v-on:click="this.command = command.name">
          <div class="card-body">
            <h5 class="card-title"><b>{{command.name}}</b></h5>
            {{ command.description }}
          </div>
        </div>
      </div>

    </div>
    <br>
    <div class="row align-items-center justify-content-center">
    <div class="col-6">
        <input class="form-control form-control-lg" placeholder="IP or hostname">
        <br>
        <button type="button" class="btn btn-lg form-control btn-primary" v-on:click="(step = 4)">Query</button>
      </div>
    </div>
    </div>
    <!-- RESULTS -->
      <PingComponent v-if="step === 4 && command === 'ping'"></PingComponent>
      <TracerouteComponent v-if="step === 4 && command === 'traceroute'"></TracerouteComponent>
      <BGPComponent v-if="step === 4 && command === 'bgp'"></BGPComponent>
  </div>
  <div class="container" v-if="step === 4">
    <div class="row justify-content-center">
    <div class="col-6">
      <button type="button" class="btn btn-lg form-control btn-primary" v-on:click="step = 1">Run another request</button>
    </div>
    </div>
  </div>
  <div class="container" id="footer">
    <br>
    <br>
    <p>powered by <u>laniakea</u></p>
  </div>
</template>

<script>
import PingComponent from './components/PingComponent.vue'
import TracerouteComponent from './components/TracerouteComponent.vue'
import BGPComponent from './components/BGPComponent.vue'

export default {
  name: 'App',
  components: {
    PingComponent,
    TracerouteComponent,
    BGPComponent
  },
  data() {
    return {
      requestStatus: "",
      step: 1,
      result: "",
      command: "",
      isOver: false,
      config: null,
      selectedNode: null
    }
  },
  mounted() {
    this.loadConfig()
  },
  methods: {
    async loadConfig(){
      // fetch config.json localy
      const response = await fetch('config.json');
      const data = await response.json();
      this.config = data
      console.log(data)
    },
    checkIfCommandEnabled(deviceId, commandId){
      console.log(deviceId, commandId)
      this.config.nodes.forEach(element => {
        if(element.id === deviceId){
          console.log("Device found: ", element.name)
          element.enabledCommands.forEach(el => {
            if(el == commandId){
              console.log("Command found: ", el)
              return true
            }
          })
        }
      });
      return true
    },
  }
}
</script>

<style>
body, html{
  background-color: #F4F3F2;
  color: #1d1d1d;
}
#app {
  font-family: "Ubuntu Mono", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}

.card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.clickable {
  cursor: pointer;
}
</style>
