<template>
      <div class="row align-items-center justify-content-center">
      <div class="col-6">

        <MapComponent :lat="59.3287" :lng="18.0717" :lat1="37.7510" :lng1="-97.8220"></MapComponent><br>

        <div class="row">
          <div class="col-5">
                <h4>🇸🇪swe-b1</h4>
                <b>drusha.network AS65001</b>
          </div>
          <div class="col-2">
            <i class="bi bi-arrow-left-right"></i>
          </div>
          <div class="col-5">
                <h4>🇺🇸 216.58.206.46</h4>
                <b>Google Inc. AS15169</b>
          </div>
        </div>
        <br>

        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col" style="text-align: left;">
                <div class="spinner-border" style="width: 1rem; height: 1rem;" role="status" v-if="!done">
                </div>
                <span v-if="done"><i class="bi bi-check-lg" style="color: green;"></i></span> <span>{{ this.requestStatus }}</span>
              </div>
              <div class="col">
                <div style="text-align: right;">
                  <span class="clickable"><i class="bi bi-clipboard"></i> Copy </span>
                  <span class="clickable"><i class="bi bi-link-45deg"></i> Link </span>
                  <span class="clickable" v-on:click="raw = !raw"><i class="bi bi-filetype-raw"></i> Raw </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" style="text-align: left;" v-if="this.result != '' && this.raw">
            <pre v-html="this.result"></pre>
          </div>
          <ul class="list-group list-group-flush" style="text-align: left;" v-if="!this.raw" id="traceres">
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                <b>1. </b>🇸🇪 swe-2 (100.64.0.80)
                </div>
                <div class="col" style="text-align: right">
                  107.623 ms  121.360 ms  104.560 ms
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <b>2. </b>🏳️ _gateway (10.0.0.1)
                </div>
                <div class="col" style="text-align: right">
                  122.659 ms  105.575 ms  106.640 ms
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <b>3. </b>🇸🇪 sto1-edge.aeza.network (77.91.84.96)
                </div>
                <div class="col" style="text-align: right">
                  112.117 ms  122.543 ms  110.901 ms
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <b>4. </b>🇷🇺 87.239.27.105.inetcom.ru (87.239.27.105)
                </div>
                <div class="col" style="text-align: right">
                  133.554 ms *
                </div>
              </div>
            </li>

            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <b>5. </b>🇸🇪 netnod-ix-ge-a-sth-1500.cloudflare.com (194.68.123.246)
                </div>
                <div class="col" style="text-align: right">
                  138.853 ms  131.733 ms  141.002 ms
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <b>6. </b>🏳️ one.one.one.one (1.1.1.1)
                </div>
                <div class="col" style="text-align: right">
                  129.541 ms  128.420 ms  129.556 ms
                </div>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <br>
</template>

<script>
import MapComponent from './MapComponent.vue';
export default {

  name: 'TracerouteComponent',
  components: {
    MapComponent
  },
  data(){
    return {
      requestStatus: '',
      result: '',
      done: false,
      raw: false
    }
  },
  mounted() {
    this.spoofTraceroute();
  },
  methods: {
    spoofTraceroute(){

      const ul = document.getElementById("traceres");  // Находим <ul> по ID
      const items = ul.getElementsByTagName('li');  // Получаем все <li> внутри <ul>

      // Скрываем все элементы
      for (let i = 0; i < items.length; i++) {
          items[i].style.display = 'none';
      }

      // Показываем элементы по одному с интервалом 1 секунда
      let index = 0;
      const intervalId = setInterval(() => {
          if (index < items.length) {
              items[index].style.display = 'block';  // Показываем элемент
              index++;
          } else {
              clearInterval(intervalId);  // Останавливаем таймер, когда все элементы показаны
          }
      }, 1000);

      let i = 0;
      this.result = "traceroute to 1.1.1.1 (1.1.1.1), 64 hops max, 40 byte packets"
      this.requestStatus = "Accepted your request!"
      setTimeout(() => {
        this.requestStatus = "Connecting to device...";
      }, 200);

      setTimeout(() => {
        this.requestStatus = "Executing command...";
      }, 500);

      let timer = setInterval(() => {
        this.requestStatus = "Command running...";
        if (i === 0) {
          this.result += `\n1  swe-2 (100.64.0.80)  107.623 ms  121.360 ms  104.560 ms`
        } else if (i === 1) {
          this.result += `\n2  _gateway (10.0.0.1)  122.659 ms  105.575 ms  106.640 ms`
        } else if (i === 2) {
          this.result += `\n3  sto1-edge.aeza.network (77.91.84.96)  112.117 ms  122.543 ms  110.901 ms`
        } else if (i === 3) {
          this.result += `\n4  * 87.239.27.105.inetcom.ru (87.239.27.105)  133.554 ms *`
        } else if (i === 4) {
          this.result += `\n5  netnod-ix-ge-a-sth-1500.cloudflare.com (194.68.123.246)  138.853 ms  131.733 ms  141.002 ms`
        } else if (i === 5) {
          this.result += `\n6  one.one.one.one (1.1.1.1)  129.541 ms  128.420 ms  129.556 ms`
          this.done = true
          this.requestStatus = "Request executed!"
        }
        i++;
        if (i > 5) {
          clearInterval(timer)
        }
      }, 1000)
    }
  }

}
</script>

<style scoped>
#map-container {
  width: 100%;
  height: 250px;
}
.map-wrapper{
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>